'use client';

import { useEffect, useState } from "react";
import JobCard from "./JobCard";  // Import the JobCard component

const PromotedJobs = () => {
  const [promotedJobs, setPromotedJobs] = useState([]);

  // Fetch promoted jobs when the component mounts
  useEffect(() => {
    const fetchPromotedJobs = async () => {
      try {
        const response = await fetch("/api/job-promoted-main-page");  // Call the API to get promoted jobs
        const data = await response.json();
        setPromotedJobs(data);  // Store the fetched jobs in state
      } catch (error) {
        console.error("Error fetching promoted jobs:", error);
      }
    };

    fetchPromotedJobs();  // Trigger the fetch function
  }, []);  // Empty dependency array to run this effect only once when the component mounts

  return (
    <div className="mx-auto mt-32 max-w-7xl sm:px-6 lg:px-8">
      <h2 className="text-2xl font-bold text-gray-800">Promoted Jobs</h2>
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
        {promotedJobs.length > 0 ? (
          promotedJobs.map((job) => <JobCard key={job._id} job={job} />)
        ) : (
          <p>Ładuję promowane oferty</p>
        )}
      </ul>
    </div>
  );
};

export default PromotedJobs;
