const testimonials = [
  {
    body: 'Platforma ozejobs.pl umożliwiła nam szybkie dotarcie do najlepszych kandydatów w branży OZE. Dzięki niej nasza firma mogła błyskawicznie rozwinąć skrzydła.',
    author: {
      name: 'Marek Kowalski',
      handle: 'marekkowalski',
      imageUrl: 'https://randomuser.me/api/portraits/men/32.jpg',
    },
  },
  {
    body: 'Z ozejobs.pl znaleźliśmy idealnych specjalistów do naszych projektów związanych z odnawialnymi źródłami energii. Prosty i przejrzysty proces rekrutacji to ogromny plus.',
    author: {
      name: 'Anna Nowak',
      handle: 'annanowak',
      imageUrl: 'https://randomuser.me/api/portraits/women/44.jpg',
    },
  },
  {
    body: 'Dzięki ozejobs.pl mogłem szybko i efektywnie znaleźć zatrudnienie w branży, która mnie fascynuje. Polecam każdemu, kto szuka pracy w OZE!',
    author: {
      name: 'Tomasz Wiśniewski',
      handle: 'tomaszwisniewski',
      imageUrl: 'https://randomuser.me/api/portraits/men/45.jpg',
    },
  },
  {
    body: 'Rekrutacja przez ozejobs.pl była szybka i bezproblemowa. Warto skorzystać z usług tej platformy, jeśli szukasz kompetentnych pracowników.',
    author: {
      name: 'Karolina Zielińska',
      handle: 'karolinazielinska',
      imageUrl: 'https://randomuser.me/api/portraits/women/36.jpg',
    },
  },
  {
    body: 'Nie spodziewałem się, że znajdę tak szybko idealne stanowisko w firmie zajmującej się energią odnawialną. ozejobs.pl to świetne narzędzie.',
    author: {
      name: 'Piotr Dąbrowski',
      handle: 'piotrdabrowski',
      imageUrl: 'https://randomuser.me/api/portraits/men/50.jpg',
    },
  },
  {
    body: 'Proces rekrutacji przebiegł bardzo sprawnie, a platforma jest naprawdę intuicyjna. Dzięki ozejobs.pl znaleźliśmy nowych specjalistów do naszego zespołu.',
    author: {
      name: 'Magdalena Maj',
      handle: 'magdalenamaj',
      imageUrl: 'https://randomuser.me/api/portraits/women/29.jpg',
    },
  },
];

export default function Example() {
  return (
    <div className="bg-amber-50 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-xl text-center">
          <h2 className="text-lg font-semibold leading-8 tracking-tight text-emerald-600">Opinie</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Poznaj opinie naszych użytkowników
          </p>
        </div>
        <div className="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
          <div className="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
            {testimonials.map((testimonial) => (
              <div key={testimonial.author.handle} className="pt-8 sm:inline-block sm:w-full sm:px-4">
                <figure className="rounded-2xl bg-orange-100 p-8 text-sm leading-6">
                  <blockquote className="text-gray-900">
                    <p>{`“${testimonial.body}”`}</p>
                  </blockquote>
                  <figcaption className="mt-6 flex items-center gap-x-4">
                    <img alt="" src={testimonial.author.imageUrl} className="h-10 w-10 rounded-full bg-gray-50" />
                    <div>
                      <div className="font-semibold text-gray-900">{testimonial.author.name}</div>
                      <div className="text-gray-600">{`@${testimonial.author.handle}`}</div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
