import Image from 'next/image';

// Component which displays promoted companies on the main page of the application.

const clients = [
  {
    name: 'Huawei Polska',
    role: 'Warszawa',
    imageSrc: 'https://logos.gpcdn.pl/loga-firm/20010131/ee4d0000-5df0-0015-d804-08dafd1ea160_280x280.png',
    xUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'TAURON Zielona Energia',
    role: 'Katowice',
    imageSrc: 'https://logos.gpcdn.pl/loga-firm/1074072617/03000000-bb2f-3863-ed73-08d9bb2c8767_280x280.png',
    xUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Polenergia S.A.',
    role: 'Warszawa',
    imageSrc: 'https://logos.gpcdn.pl/loga-firm/1074018840/2c580000-43a8-f403-a11d-08da027467ec_280x280.png',
    xUrl: '#',
    linkedinUrl: '#',
  },
  // More clients...
];

export default function PromotedClients() {
  return (
    <div className="bg-orange-100 py-32">
      <div className="mx-auto max-w-7xl px-6 text-center lg:px-8">
        <div className="mx-auto max-w-2xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Polecani Pracodawcy
          </h2>
          <p className="mt-4 text-lg leading-8 text-gray-600">
            Poznaj firmy które wyróżniają się na rynku wyjątkowymi ofertami i benefitami dla swoich
            pracowników.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
        >
          {clients.map((client) => (
            <li key={client.name}>
              <img
                alt={client.name}
                src={client.imageSrc}
                width={224}
                height={224}
                className="mx-auto rounded-lg border shadow-gray-200 shadow-md "
              />
              <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900">
                {client.name}
              </h3>
              <p className="text-sm leading-6 text-gray-600">{client.role}</p>
              <ul role="list" className="mt-6 flex justify-center gap-x-6">
                <li>
                  <a href={client.xUrl} className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">X</span>
                    {/* SVG for X logo */}
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      className="h-5 w-5"
                    >
                      {/* SVG path here */}
                    </svg>
                  </a>
                </li>
                <li>
                  <a href={client.linkedinUrl} className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">LinkedIn</span>
                    {/* SVG for LinkedIn logo */}
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      className="h-5 w-5"
                    >
                      {/* SVG path here */}
                    </svg>
                  </a>
                </li>
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
