"use client";

import Navbar from "./components/Navbar";
import Divider from "./components/Divider";
import Hero from "./components/Hero";
import Promoted from "./components/Promoted";
import Footer from "./components/Footer";
import Testimonials from "./components/Testimonials";
import Contact from "./components/Contact";
import PromotedJobs from "./components/PromotedJobs";
import JobBrowser from "./components/JobBrowser";

export default function Home() {
  return (
    <div>
      <Navbar />
      <Divider />
      <Hero />
      <Divider />
      <PromotedJobs />
      <section id="job-browser">
        <JobBrowser />
      </section>
      <Divider />
      <Promoted />
      <Divider />
      <Testimonials />
      <Divider />
      <Contact />
      <Footer />
    </div>
  );
}
