"use client";

import { useState, useEffect } from "react";
import SearchBar from "./SearchBar";  // Import SearchBar
import JobCard from "./JobCard";  // Import JobCard

const JobBrowser = () => {
  const [jobs, setJobs] = useState([]);  // State to hold job search results
  const [page, setPage] = useState(1);   // Track the current page
  const [loading, setLoading] = useState(false);  // To show a loading indicator
  const [hasMore, setHasMore] = useState(true);  // Track if there are more jobs to load
  const [activeTab, setActiveTab] = useState("Sprzedaż"); // State for active job category

  // Fetch jobs based on page number and job category
  const fetchJobs = async (page) => {
    setLoading(true);
    try {
      const jobCategory = encodeURIComponent(activeTab);  // Use activeTab for job category

      const response = await fetch(`/api/job-browser?jobCategory=${jobCategory}&page=${page}&limit=12`);  // Add jobCategory to each request
      const data = await response.json();

      // Ensure the response is an array before appending
      if (Array.isArray(data)) {
        setJobs((prevJobs) => [...prevJobs, ...data]);  // Append, don't overwrite
      } else {
        console.error("API did not return an array:", data);
      }

      // If there are no more jobs, set hasMore to false
      if (data.length === 0) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
    setLoading(false);
  };

  // Load jobs when the component mounts and when the page number or activeTab changes
  useEffect(() => {
    setJobs([]);  // Clear existing jobs when changing category
    setPage(1);   // Reset page number
    setHasMore(true);  // Reset hasMore when changing tab
  }, [activeTab]);

  useEffect(() => {
    if (hasMore) {
      fetchJobs(page);
    }
  }, [page, activeTab]);  // Refetch when page or activeTab changes

  // Handle "Load More" button click
  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);  // Increase the page number to fetch next set of jobs
  };

  return (
    <div>
      {/* SearchBar Component */}
      <SearchBar setJobs={setJobs} setActiveTab={setActiveTab} />  {/* Pass setActiveTab */}

      {/* Render JobCards */}
      <div className="mx-auto max-w-7xl sm:px-6 pb-16 lg:px-8">
        <ul className="mt-12 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
          {jobs.length > 0 ? (
            jobs.map((job) => <JobCard key={job._id} job={job} />)
          ) : (
            <p>No jobs found</p>
          )}
        </ul>

        {/* Load More Button */}
        {hasMore && !loading && (
          <div className="text-center mt-16">
            <button
              onClick={handleLoadMore}
              className="px-4 py-2 bg-emerald-600 text-white rounded-md hover:bg-emerald-500"
            >
              Pokaż więcej ofert
            </button>
          </div>
        )}

        {/* Loading Indicator */}
        {loading && <p className="text-center mt-16">Loading more jobs...</p>}
      </div>
    </div>
  );
};

export default JobBrowser;
