'use client';

import Link from 'next/link';

const JobCard = ({ job }) => {
  return (
    <li className="overflow-hidden rounded-xl border  bg-white border-gray-200">
      {/* Card Header */}
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-orange-100 p-6">
        <img
          alt={job.employer_name || 'Company logo'}
          src={job.company_logo || 'Company logo'}
          className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
        />
        <div className="text-sm font-medium leading-6 text-gray-900">
          {job.employer_name || 'Nieznany pracodawca'}
        </div>
      </div>

      {/* Card Body */}
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        {/* Job Title */}
        <div className="flex justify-between gap-x-4 py-3">
          <dt className="text-gray-500">Stanowisko</dt>
          <dd className="text-gray-700 text-right">
            <Link href={`/jobpage/${job.advertId}`}>
              <span className="text-emerald-600 hover:underline">
                {job.job_title || 'Nie podano'}
              </span>
            </Link>
          </dd>
        </div>

        {/* Location */}
        <div className="flex justify-between gap-x-4 py-3">
          <dt className="text-gray-500">Lokalizacja</dt>
          <dd className="text-gray-700 text-right">
            {job.location || 'Nie podano lokalizacji'}
          </dd>
        </div>

        {/* Contract Type */}
        <div className="flex justify-between gap-x-4 py-3">
          <dt className="text-gray-500">Rodzaj umowy</dt>
          <dd className="text-gray-700 text-right">
            {job.contract_types || 'Nie podano'}
          </dd>
        </div>

        {/* Salary */}
        <div className="flex justify-between gap-x-4 py-3">
          <dt className="text-gray-500">Wynagrodzenie</dt>
          <dd className="text-gray-700 text-right">
            {job.salary_components || 'Nie podano'}
          </dd>
        </div>
      </dl>
    </li>
  );
};

export default JobCard;
