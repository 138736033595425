"use client";
import { useState, useEffect } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const SearchBar = ({ setJobs, setActiveTab }) => {
  const [searchQuery, setSearchQuery] = useState(""); // For job title or company name
  const [locationQuery, setLocationQuery] = useState(""); // For location
  const [suggestions, setSuggestions] = useState([]); // Combined suggestions for job titles and company names
  const [locationSuggestions, setLocationSuggestions] = useState([]); // Location autocomplete
  const [activeTabState, setActiveTabState] = useState("Sprzedaż"); // Default active tab for job type
  const [loading, setLoading] = useState(false); // To handle loading state

  // Fetch suggestions for both job titles and employer names when at least 3 characters are entered
  const fetchSuggestions = async (query) => {
    if (query.length >= 3) {
      let response = await fetch(`/api/autocomplete?type=stanowisko&search=${query}`);
      if (response.status === 404) {
        response = await fetch(`/api/autocomplete?type=firma&search=${query}`);
      }
      const data = await response.json();
      setSuggestions(data);
    } else {
      setSuggestions([]);
    }
  };

  const fetchLocationSuggestions = async (query) => {
    if (query.length >= 3) {
      const response = await fetch(`/api/autocomplete?type=lokalizacja&search=${query}`);
      const data = await response.json();
      setLocationSuggestions(data);
    } else {
      setLocationSuggestions([]);
    }
  };

  // Handle the search when the "Szukaj" button is clicked
  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Check if the search is for a job title or employer name
    const jobTitleParam = searchQuery ? `&jobTitle=${encodeURIComponent(searchQuery)}` : "";
    const employerNameParam = searchQuery ? `&employerName=${encodeURIComponent(searchQuery)}` : "";  
    const locationParam = locationQuery ? `&location=${encodeURIComponent(locationQuery)}` : "";
    const jobCategoryParam = `jobCategory=${encodeURIComponent(activeTabState)}`;  

    const response = await fetch(`/api/job-browser?${jobCategoryParam}${jobTitleParam}${employerNameParam}${locationParam}`);
    const data = await response.json();

    console.log("API Response:", data); // Log the response to see if you're receiving jobs
    setJobs(data); // Pass the fetched jobs to the parent component
    setLoading(false);
  };

  // Trigger autocomplete suggestions for job titles and employer names
  useEffect(() => {
    fetchSuggestions(searchQuery);
  }, [searchQuery]);

  // Trigger autocomplete suggestions for locations
  useEffect(() => {
    fetchLocationSuggestions(locationQuery);
  }, [locationQuery]);

  // Handle Tab Click (Job Type Selection)
  const handleTabClick = (tab) => {
    setActiveTabState(tab); // Update local state
    setActiveTab(tab); // Pass the active tab to JobBrowser to trigger fetch
  };

  return (
    <div className="bg-amber-50 mx-auto max-w-7xl px-6 pb-12 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
      <div className="bg-amber-50 px-6 py-12 sm:py-16 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Wyszukiwarka ofert</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Wybierz interesujące Cię stanowisko, firmę bądź słowo kluczowe, a my znajdziemy dla Ciebie najlepsze oferty pracy.
          </p>
        </div>
      </div>

      {/* Job Type Tabs */}
      <div className="flex flex-wrap sm:grid-rows-3 space-x-4 border-b ">
        {['Sprzedaż', 'Instalacje', 'Projektowanie', 'Zarządzanie', 'Marketing', 'IT', 'Finanse', 'Elektromobilność', 'Administracja'].map((tab) => (
          <button
            key={tab}
            className={`p-2 ${activeTabState === tab ? 'hover:text-emerald-600 border-b-2 border-emerald-500 text-emerald-500' : 'text-gray-600' }`}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Inputs Section */}
      <div className="flex space-x-4 mt-4">
        {/* Position/Company Input with Autocomplete */}
                <div className="relative w-1/2">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Stanowisko, firma, słowo kluczowe"
              className="w-full p-3 border rounded-md focus:ring focus:ring-orange-300"
              onBlur={() => setTimeout(() => setSuggestions([]), 200)}
            />
            {suggestions.length > 0 && (
              <ul className="absolute bg-white border mt-2 w-full max-h-64 overflow-y-auto">
                {suggestions.slice(0, 8).map((suggestion) => (
                  <li
                    key={suggestion}
                    onClick={() => {
                      setSearchQuery(suggestion);  
                      setSuggestions([]);
                    }}
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </div>

        {/* Location Input with Autocomplete */}
        <div className="relative w-1/2">
  <input
    type="text"
    value={locationQuery}
    onChange={(e) => setLocationQuery(e.target.value)}
    placeholder="Lokalizacja"
    className="w-full p-3 border rounded-md focus:ring focus:ring-orange-300"
    onBlur={() => setTimeout(() => setLocationSuggestions([]), 200)}
  />
  {locationSuggestions.length > 0 && (
    <ul className="absolute bg-white border mt-2 w-full max-h-64 overflow-y-auto">
      {locationSuggestions.slice(0, 8).map((suggestion) => (
        <li
          key={suggestion}
          onClick={() => {
            setLocationQuery(suggestion);
            setLocationSuggestions([]);
          }}
          className="p-2 hover:bg-gray-100 cursor-pointer"
        >
          {suggestion}
        </li>
      ))}
    </ul>
  )}
</div>
      </div>

      {/* Search Button */}
      <div className="mt-4">
        <button
          onClick={handleSearch}
          className="w-full p-3 bg-emerald-600 hover:bg-emerald-500 text-white rounded-md flex items-center justify-center"
        >
          {loading ? "Loading..." : (
            <>
              <MagnifyingGlassIcon className="w-5 h-5 mr-2" />
              Szukaj
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
